import React, { Component } from "react";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemPanel, AccordionItemButton } from "react-accessible-accordion";
// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";

class Accordion01 extends Component {
  render() {
    return (
      <Accordion className="accodion-style--1" preExpanded={"0"}>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Task Mastery Accelerator</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="description">Propel productivity through streamlined workflows, ensuring rapid task execution and project efficiency.</p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Collaboration Hub Pro</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="description">Cultivate seamless collaboration, centralizing tasks and communication for teams to achieve goals effortlessly.</p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Time Management Prodigy</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="description">Master time with precision, utilizing intuitive tools to boost efficiency and prioritize tasks for maximum productivity.</p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Automated Workflow Genius</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="description">Experience the future of work with automation, as our genius transforms routine tasks, unleashing unparalleled productivity.</p>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    );
  }
}

export default Accordion01;
