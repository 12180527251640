import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

class TabsTwo extends Component {
  render() {
    let tab1 = "Main skills",
      tab2 = "Experience",
      tab3 = "Education & Certification";
    const { tabStyle } = this.props;
    return (
      <div>
        {/* Start Tabs Area */}
        <div className="tabs-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <Tabs>
                  <TabList className={`${tabStyle}`}>
                    <Tab>{tab1}</Tab>
                    <Tab>{tab2}</Tab>
                    <Tab>{tab3}</Tab>
                  </TabList>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          <span className="main-title">Principal Software Engineer</span>
                          <br></br>
                          Guiding development with expertise and innovation.
                        </li>
                        <li>
                          <span className="main-title">Senior Software Engineer</span>
                          <br></br>
                          Experienced coder, problem solver, tech leader.
                        </li>
                        <li>
                          <span className="main-title">Software Engineer</span>
                          <br></br>
                          Designs and develops software solutions for optimal functionality and efficiency.
                        </li>
                      </ul>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          <span className="main-title">Principal Software Engineer</span> <span> - Onward Tech Ltd</span>
                          <br></br>
                          Feb ‘2022 to Till Now
                        </li>
                        <li>
                          <span className="main-title">Senior Software Engineer</span> <span> - Kyyba Pvt. Ltd</span>
                          <br></br>
                          May ‘2019 to Feb ‘2022
                        </li>
                        <li>
                          <span className="main-title">Software Engineer</span> <span> - Smartek21 Pvt. Ltd</span>
                          <br></br>
                          May ‘2018 to April ‘2019
                        </li>
                        <li>
                          <span className="main-title">Software Engineer</span> <span> - Minmini Ventures Pvt. Ltd</span>
                          <br></br>
                          Feb ’2016 to May ‘2018
                        </li>
                      </ul>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          <span className="main-title">Master of Science in IT</span>
                          <span> - Madras University, Chennai, India</span>
                          <br></br> 2017-2019
                        </li>
                        <li>
                          <span className="main-title">Bachelor of Computer Science</span>
                          <span> - TNC College, Chennai, India</span>
                          <br></br> 2014
                        </li>
                      </ul>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
        {/* End Tabs Area */}
      </div>
    );
  }
}

export default TabsTwo;
