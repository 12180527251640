import React from "react";
import PageHelmet from "../component/common/Helmet";
import { FiHeadphones, FiMail, FiMapPin, FiChevronUp } from "react-icons/fi";
import ContactTwo from "../elements/contact/ContactTwo";
import ScrollToTop from "react-scroll-up";
import Header from "../component/header/HeaderTwo";
import Footer from "../component/footer/Footer";
import useCursor from "../useCursor.js";

const Contact = () => {
  useCursor();

  return (
    <>
      <div className="trito_tm_all_wrap" data-magic-cursor="show"></div>
      <PageHelmet pageTitle="Contact" pageDescription="Contact Page Description" pageKeywords={["Profile"]} />
      <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

      {/* Start Breadcrump Area */}
      <div className="rn-page-title-area pt--90 pb--90 bg_image bg_image--17" data-black-overlay="6">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rn-page-title text-center pt--100">
                <h1 className="title theme-gradient">Contact Me</h1>
                <p>Have a project to discuss?</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrump Area */}

      {/* Start Contact Top Area  */}
      <div className="rn-contact-top-area ptb--90 bg_color--5">
        <div className="container">
          <div className="row">
            {/* Start Single Address  */}
            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="rn-address">
                <div className="icon">
                  <FiHeadphones />
                </div>
                <div className="inner">
                  <h4 className="title">Contact With Mobile Number</h4>
                  <p>
                    <a href="tel:+91 7200 33 9195">+91 7200 33 9195</a>
                  </p>
                  <p>&nbsp;</p>
                </div>
              </div>
            </div>
            {/* End Single Address  */}

            {/* Start Single Address  */}
            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
              <div className="rn-address">
                <div className="icon">
                  <FiMail />
                </div>
                <div className="inner">
                  <h4 className="title">Email Address</h4>
                  <p>
                    <a href="mailto:mikdanjey@gmail.com">mikdanjey@gmail.com</a>
                  </p>
                  <p>&nbsp;</p>
                </div>
              </div>
            </div>
            {/* End Single Address  */}

            {/* Start Single Address  */}
            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
              <div className="rn-address">
                <div className="icon">
                  <FiMapPin />
                </div>
                <div className="inner">
                  <h4 className="title">Location</h4>
                  <p>No: 864 A, 26th Street B.V.Colony, Perambur, Chennai</p>
                </div>
              </div>
            </div>
            {/* End Single Address  */}
          </div>
        </div>
      </div>
      {/* End Contact Top Area  */}

      {/* Start Contact Page Area  */}
      <div className="rn-contact-page ptb--90 bg_color--1">
        <ContactTwo />
      </div>
      {/* End Contact Page Area  */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      <Footer />
      <div className="mouse-cursor cursor-outer"></div>
      <div className="mouse-cursor cursor-inner"></div>
    </>
  );
};

export default Contact;
