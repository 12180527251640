import React from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderTwo";
import Footer from "../component/footer/Footer";
import useCursor from "../useCursor.js";
import ProgressTwo from "../blocks/progressbar/ProgressTwo.jsx";
import TabTwo from "../elements/tab/TabTwo";
import BrandTwo from "../elements/BrandTwo";

const ServiceList = [
  {
    icon: "/assets/images/logo/experience-ecom.png",
    title: "Ecommerce",
    description:
      "E-commerce, or electronic commerce, refers to buying and selling goods and services online. It encompasses everything from online retail to digital transactions and is a cornerstone of the modern economy.",
    url: "experience/e-commerce",
  },
  {
    icon: "/assets/images/logo/experience-ott.png",
    title: "OTT",
    description: "OTT (over-the-top) is streaming content over the internet without traditional cable or satellite. It's a flexible way to watch your favorite shows and movies.",
    url: "experience/ott",
  },
  {
    icon: "/assets/images/logo/experience-loan.png",
    title: "Learning Management Systems",
    description:
      "A Learning Management System (LMS) delivers educational content and training programs. It offers features like course creation, assessments, and communication tools for flexible online learning in schools and businesses.",
    url: "experience/lms",
  },
  {
    icon: "/assets/images/logo/experience-business.png",
    title: "Business Development ",
    description: "Business development drives company growth through strategic partnerships, market expansion, and customer acquisition. It identifies and pursues new business opportunities.",
    url: "experience/business-developement",
  },
  {
    icon: "/assets/images/logo/experience-iot.png",
    title: "Internet of Things",
    description: "IoT connects devices to the internet, enabling them to communicate, share data, and perform tasks. It powers smart homes, automated industries, and seamless connectivity.",
    url: "experience/iot",
  },
  {
    icon: "/assets/images/logo/experience-erp.png",
    title: "Enterprise Resource Planning",
    description: "Enterprise Resource Planning (ERP) centralizes business processes like finance, HR, and procurement into one system, enhancing efficiency and providing real-time insights.",
    url: "experience/enterprise-resource-planning",
  },
];
const Experience = () => {
  useCursor();

  return (
    <>
      <div className="trito_tm_all_wrap" data-magic-cursor="show"></div>
      <PageHelmet pageTitle="Experience" pageDescription="Mikdan Tech Solutions" pageKeywords={["Profile"]} />

      <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

      {/* Start Breadcrump Area */}
      <Breadcrumb title={"Experience"} />
      {/* End Breadcrump Area */}

      {/* Start About Area  */}
      <div className="rn-about-area ptb--90 bg_color--1">
        <div className="rn-about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-7">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">Tech Stack Expertise</h2>
                    <p className="description">
                      Explore my journey as a seasoned full stack developer, where expertise meets innovation. Discover a track record of creating captivating user interfaces, employing the latest
                      technologies, and delivering seamless digital experiences across diverse projects.
                    </p>
                  </div>
                  <div className="row mt--30">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">Our Experience</h3>
                        <ProgressTwo ProgressStyle="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-5 mt_md--40 mt_sm--40">
                <div className="thumbnail">
                  <img className="w-100" src="/assets/images/about/about-3.webp" alt="About Images" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End About Area  */}

      {/* Start Service Area */}
      <div className="service-area creative-service-wrapper pt--90 pb--90 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center mb--30">
                <h2>Industries of Expertise</h2>
                <p>Demonstrating proficiency across various sectors, our expertise spans a diverse range of industries, ensuring tailored solutions and strategic insights for your business needs.</p>
              </div>
            </div>
          </div>
          <div className="row creative-service">
            {ServiceList.map((val, i) => (
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                <a className="text-center" href={val.url}>
                  <div className="service service__style--2">
                    <div className="icon">
                      <img src={val.icon}></img>
                    </div>
                    <div className="content">
                      <h3 className="title">{val.title}</h3>
                      <p>{val.description}</p>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* End Service Area */}

      {/* Start About Area */}
      <div id="about" className="fix">
        <div className="about-area ptb--90  bg_color--1">
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35">
                <div className="col-lg-5">
                  <div className="thumbnail">
                    <img className="w-100" src="/assets/images/about/about-7.jpg" alt="About Images" />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h2 className="title">What I do</h2>
                      <p className="description">
                        I am Manikandan A, a seasoned Principal Software Engineer with over 8 years of experience in the IT industry, specializing in Full Stack development. My expertise lies in
                        crafting dynamic and scalable web applications using React, Node.js, and MongoDB. Throughout my career, I have demonstrated a strong ability to lead development projects from
                        the ground up, ensuring that all aspects of software development, from conceptual design to deployment, are executed with precision and efficiency.
                      </p>
                    </div>
                    <div className="row mt--30">
                      <TabTwo tabStyle="tab-style--1" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* End About Area */}

      {/* Start About Area  */}
      <div className="rn-about-area ptb--90 bg_color--5">
        <div className="container">
          <div className="row row--35 align-items-center">
            <div className="col-lg-6 order-2 order-lg-1">
              <div className="about-inner inner">
                <div className="section-title">
                  <h2 className="title">Full Stack Dev Expert - Digital Impact</h2>
                  <p className="description">
                    Unlock the potential of unparalleled full stack development tailored to your digital needs. From software and mobile app to web development, we excel in crafting feature-rich
                    applications with a flawless front-end and a robust back-end. Trusted across diverse business domains, our websites stand out for their enduring quality. Our server-side
                    development expertise, combined with the latest tech tools, ensures optimal performance for your back-end applications. Adapting to the latest web development trends, we provide
                    SEO-optimized, comprehensive full stack solutions, setting new standards for digital excellence. Join us at the forefront of innovation, where your success is our priority.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              <div className="thumbnail position-relative">
                <img className="w-100" src="/assets/images/about/about-3.webp" alt="About Images" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End About Area  */}

      {/* Start Brand Area */}
      <div className="rn-brand-area ptb--90">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title service-style--3 text-center mb--15">
                <h2 className="title">Comprehensive Skill Set</h2>
                <p>Versatile and comprehensive professional skill set encompassing expertise in diverse domains.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <BrandTwo />
            </div>
          </div>
        </div>
      </div>
      {/* End Brand Area */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      <Footer />
      <div className="mouse-cursor cursor-outer"></div>
      <div className="mouse-cursor cursor-inner"></div>
    </>
  );
};

export default Experience;
