import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import PageScrollTop from "./component/PageScrollTop";

// Home layout
import Home from "./home/Home";

// Element Layout
import Service from "./elements/Service";
import ServiceDetails from "./elements/ServiceDetails";
import About from "./elements/About";
import Experience from "./elements/Experience";
import Contact from "./elements/Contact";
import PortfolioDetails from "./elements/PortfolioDetails";
import Blog from "./elements/Blog";
import BlogDetails from "./elements/BlogDetails";
import NotFound from "./elements/NotFound";
import WebDevelopment from "./elements/WebDevelopment";
import MicroservicesArchitecture from "./elements/MicroservicesArchitecture";
import DevOps from "./elements/DevOps";
import IoTAppControl from "./elements/IoTAppControl";
import WebAndAPIAutomation from "./elements/WebAndAPIAutomation";
import NativeAndHybridApps from "./elements/NativeAndHybridApps";
import EnterprisesResourcePlanning from "./elements/EnterprisesResourcePlanning";
import Iot from "./elements/Iot";
import BusinessDevelopement from "./elements/BusinessDevelopement";
import Lms from "./elements/Lms";
import Ott from "./elements/Ott";
import Ecommerce from "./elements/Ecommerce";
import ERP from "./elements/ERP";

function App() {
  return (
    <BrowserRouter basename={"/"}>
      <PageScrollTop>
        <Switch>
          <Route exact path={`/`} component={Home} />

          {/* Element Layot */}
          <Route exact path={`/service`} component={Service} />
          <Route exact path={`/service-details`} component={ServiceDetails} />
          <Route exact path={`/contact`} component={Contact} />
          <Route exact path={`/about`} component={About} />
          <Route exact path={`/portfolio-details`} component={PortfolioDetails} />
          <Route exact path={`/blog`} component={Blog} />
          <Route exact path={`/blog-details`} component={BlogDetails} />
          <Route exact path={`/web-development`} component={WebDevelopment} />
          <Route exact path={`/microservices-architecture`} component={MicroservicesArchitecture} />
          <Route exact path={`/dev-ops`} component={DevOps} />
          <Route exact path={`/iot-app-control`} component={IoTAppControl} />
          <Route exact path={`/web-and-api-automation`} component={WebAndAPIAutomation} />
          <Route exact path={`/native-and-hybrid-apps`} component={NativeAndHybridApps} />
          <Route exact path={`/experience`} component={Experience} />
          <Route exact path={`/experience/enterprise-resource-planning`} component={EnterprisesResourcePlanning} />
          <Route exact path={`/experience/iot`} component={Iot} />
          <Route exact path={`/experience/business-developement`} component={BusinessDevelopement} />
          <Route exact path={`/experience/lms`} component={Lms} />
          <Route exact path={`/experience/ott`} component={Ott} />
          <Route exact path={`/experience/e-commerce`} component={Ecommerce} />
          <Route exact path={`/experience/erp`} component={ERP} />
          <Route path={`/404`} component={NotFound} />
          <Route component={NotFound} />
        </Switch>
      </PageScrollTop>
    </BrowserRouter>
  );
}

export default App;
