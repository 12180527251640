import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";

function PageHelmet({ pageTitle, pageDescription }) {
  return (
    <Helmet>
      <title>{pageTitle}</title>
      <meta name="description" content={pageDescription} />
      <meta property="og:description" content={pageDescription} />
      <meta property="twitter:description" content={pageDescription} />
    </Helmet>
  );
}

PageHelmet.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  pageDescription: PropTypes.string.isRequired,
};

export default PageHelmet;
