import React from "react";
import { ProgressBar } from "react-bootstrap";

const ProgressTwo = (props) => {
  return (
    // Start Single Progressbar
    <div className={`rn-progress-bar ${props.ProgressStyle}`}>
      <div className="single-progress custom-color--1">
        <h6 className="title">Web Development</h6>
        <ProgressBar animated now={92} />
        <span className="label">92%</span>
      </div>

      <div className="single-progress custom-color--2">
        <h6 className="title">Schema Design</h6>
        <ProgressBar animated now={98} />
        <span className="label">98%</span>
      </div>

      <div className="single-progress custom-color--3">
        <h6 className="title">E-Commerce Development</h6>
        <ProgressBar animated now={80} />
        <span className="label">80%</span>
      </div>

      <div className="single-progress custom-color--4">
        <h6 className="title">Development</h6>
        <ProgressBar animated now={57} />
        <span className="label">57%</span>
      </div>
    </div>
  );
};

export default ProgressTwo;
