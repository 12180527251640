import React, { Component } from "react";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemPanel, AccordionItemButton } from "react-accessible-accordion";
// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";

class Accordion02 extends Component {
  render() {
    return (
      <Accordion className="accodion-style--1" preExpanded={"0"}>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Data Converter</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>Transforms text between encoding and decoding, converting string and JSON to CSV and vice versa.</p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Data Formatter</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>Formats JSON data for improved readability and structure in a single line.</p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Random Data</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>Generates random data, including passwords, unique identifiers, and mock credit/debit card information.</p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Bulk Generator</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>Generates bulk data for users and products, facilitating large-scale testing or simulation scenarios.</p>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    );
  }
}

export default Accordion02;
