import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderTwo";
import Footer from "../component/footer/Footer";

class ServiceDetails extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="ERP" pageDescription="Mikdan Tech Solutions" pageKeywords={["Profile"]} />
        {/* End Pagehelmet  */}
        <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

        {/* Start Breadcrump Area */}
        <div className="rn-page-title-area pt--90 pb--90 bg_image bg_image--5" data-black-overlay="5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h1 className="title theme-gradient">ERP</h1>
                  <p>Integrated business management software for seamless operations and data-driven decision-making.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--90 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="thumb">
                          <img className="w-100" src="/assets/images/service/service-01.webp" alt="Native and Hybrid Apps" />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <p>
                            Enterprise Resource Planning (ERP) is a comprehensive software solution designed to integrate and streamline various business processes within an organization. It acts as a
                            centralized hub, connecting different departments and functions, such as finance, human resources, supply chain, and customer relationship management.
                          </p>
                          <p>
                            The primary goal of ERP is to enhance efficiency, data accuracy, and collaboration by providing a unified platform where real-time information is accessible across the
                            entire organization. ERP systems typically feature modules tailored to specific business functions, allowing for seamless communication and data flow between different
                            departments.
                          </p>
                          <h4 className="title">Streamlining Business Ops</h4>
                          <ul className="liststyle">
                            <li>Efficient Resource Management</li>
                            <li>Improved Decision-Making</li>
                            <li>Enhanced Collaboration</li>
                            <li>Increased Productivity</li>
                            <li>Scalability and Adaptability</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </>
    );
  }
}
export default ServiceDetails;
