import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import Pagination from "../elements/common/Pagination";
import BlogList from "../elements/blog/BlogList";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderTwo";
import Footer from "../component/footer/Footer";

class Blog extends Component {
  render() {
    return (
      <>
        <PageHelmet pageTitle="Blog" pageDescription="Mikdan Tech Solutions" pageKeywords={["Profile"]} />
        <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
        {/* Start Breadcrump Area */}
        <Breadcrumb title={"Blog"} />
        {/* End Breadcrump Area */}

        {/* Start Blog Area */}
        <div className="rn-blog-area ptb--90 bg_color--1">
          <div className="container">
            <div className="row mb-60">
              <div className="col-lg-12">
                <div className="section-title section-title--2 text-center">
                  <h2 className="title">Latest Blog</h2>
                  <p>
                    Dive into our comprehensive technical blog offering expert insights, detailed how-tos, and the latest innovations in the ever-evolving world of technology. Stay ahead of the curve
                    with in-depth articles on programming, cybersecurity, AI, machine learning, and much more. Explore practical guides, thought-provoking analyses, and hands-on tutorials to empower
                    your journey in the digital realm.
                  </p>
                </div>
              </div>
            </div>
            <BlogList />
            <div className="row mt--20">
              <div className="col-lg-12">
                {/* Start Pagination Area */}
                <Pagination />
                {/* End Pagination Area */}
              </div>
            </div>
          </div>
        </div>
        {/* End Blog Area */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </>
    );
  }
}
export default Blog;
