import React, { useEffect } from "react";
import ScrollToTop from "react-scroll-up";
import TextLoop from "react-text-loop";
import Footer from "../component/footer/Footer";
import { FiMonitor, FiCpu, FiGitCommit, FiSmartphone, FiWifi, FiSettings, FiChevronUp, FiCheck } from "react-icons/fi";
import Header from "../component/header/Header";
import Helmet from "../component/common/Helmet";
import useCursor from "../useCursor.js";
import Slider from "react-slick";
import { slickDot } from "../component/script";
import BlogContent from "../elements/blog/BlogContent";
import Accordion01 from "../elements/Accordion";
import Accordion02 from "../elements/Accordion2";
import BrandTwo from "../elements/BrandTwo";

const SlideList = [
  {
    textPosition: "text-left",
    category: "EMBARK ON A JOURNEY THROUGH CODE AND CREATIVITY",
  },
];

const ServiceList = [
  {
    icon: <FiMonitor />,
    title: "Web Development",
    description: "Creating and maintaining websites/apps with HTML, CSS, JavaScript; combining front-end and back-end technologies for functionality.",
    url: "web-development",
  },
  {
    icon: <FiCpu />,
    title: "Microservices Architecture",
    description: "Enabling scalable, modular software development for agility and efficiency by breaking down applications into independent, manageable services.",
    url: "microservices-architecture",
  },
  {
    icon: <FiGitCommit />,
    title: "DevOps",
    description: "DevOps integrates dev and ops, fostering collaboration for continuous delivery, automation, and efficiency in the software lifecycle.",
    url: "dev-ops",
  },
  {
    icon: <FiWifi />,
    title: "IoT App Control",
    description: "Facilitates seamless management and interaction with IoT devices through a user-friendly and efficient application interface.",
    url: "iot-app-control",
  },
  {
    icon: <FiSettings />,
    title: "Web & API Automation",
    description: "Enhances efficiency by automating testing and tasks, ensuring seamless performance and reliability in web and API applications.",
    url: "web-and-api-automation",
  },
  {
    icon: <FiSmartphone />,
    title: "Native & Hybrid Apps",
    description: "Combines Native speed with Hybrid flexibility, optimizing user experience across platforms for efficient and versatile application development.",
    url: "native-and-hybrid-apps",
  },
];
const PostList = BlogContent.slice(0, 5);
let namesItemOne = ["Web, API, and IoT Mastery", "Automation Guru for Efficiency", "Mobile App Wizard (Native & Hybrid)", "Database Maestro (SQL & NoSQL)"];
let namesItemTwo = ["ES6+ JavaScript & TypeScript Pro", "React, Redux, Node.js, Express Savvy", "Cloud Architect with AWS and Oracle", "DevOps Maestro with Top Dev Tools"];
const Home = () => {
  useCursor();
  useEffect(() => {
    // Preload the image
    const image = new Image();
    image.src = "/assets/images/about/designer-avatar.webp";
  }, []);
  return (
    <div>
      <div className="trito_tm_all_wrap" data-magic-cursor="show"></div>
      <Helmet pageTitle="Mikdan Tech Solutions" />
      {/* Start Header Area  */}
      <Header headerPosition="header--static" logo="symbol-dark" color="color-black" />
      {/* End Header Area  */}

      <div className="slider-wrapper">
        {/* Start Single Slide */}
        {SlideList.map((value, index) => (
          <div className="slide designer-portfolio slider-style-3  d-flex align-items-center justify-content-center bg_color--5 rn-slider-height" key={index}>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-5">
                  <div className="designer-thumbnail">
                    <img src="/assets/images/about/designer-avatar.webp" alt="Slider Images" loading="eager" />
                  </div>
                </div>
                <div className="col-lg-7 mt_md--40 mt_sm--40">
                  <div className={`inner ${value.textPosition}`}>
                    {value.category ? <span>{value.category}</span> : ""}
                    <h1 className="title">
                      I&rsquo;M MANIKANDAN A<br />
                      <TextLoop>
                        <span>Lead Software Engineer</span>
                        <span>Full Stack Developer</span>
                        <span>IoT Developer</span>
                        <span>Software Architect</span>
                      </TextLoop>{" "}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        {/* End Single Slide */}
      </div>

      {/* Start Service Area */}
      <div className="service-area creative-service-wrapper pt--90 pb--90 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center mb--30">
                <h2>Cutting-edge Capabilities</h2>
                <p>Cutting-edge technologies driving optimized capabilities for superior performance and innovation.</p>
              </div>
            </div>
          </div>
          <div className="row creative-service">
            {ServiceList.map((val, i) => (
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                <a className="text-center" href={val.url}>
                  <div className="service service__style--2">
                    <div className="icon">{val.icon}</div>
                    <div className="content">
                      <h3 className="title">{val.title}</h3>
                      <p>{val.description}</p>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* End Service Area */}

      {/* Start About Area  */}
      <div className="rn-about-area ptb--90 bg_color--5">
        <div className="container">
          <div className="row row--35">
            <div className="col-lg-6">
              <div className="thumbnail">
                <img className="w-100" src="/assets/images/about/about-4.webp" alt="About Images" loading="lazy" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-inner inner">
                <div className="section-title">
                  <h2 className="title">Streamlined Productivity Solutions</h2>
                  <p className="description">Optimize your output and streamline tasks with our productivity-focused solutions, unlocking efficiency through enhanced workflows.</p>
                </div>
                <div className="accordion-wrapper mt--30">
                  <Accordion01 />
                </div>
                <div className="about-button mt--50">
                  <a className="rn-button-style--2 btn-solid" href="/experience">
                    Know More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End About Area  */}

      {/* Start About Area  */}
      <div className="rn-about-area ptb--90 bg_color--5">
        <div className="container">
          <div className="row row--35 align-items-center">
            <div className="col-lg-6 order-2 order-lg-1">
              <div className="about-inner inner">
                <div className="section-title">
                  <h2 className="title">Code Enthusiast</h2>
                  <p className="description">
                    Dive into the exhilarating world of coding and algorithms, where passion meets precision. Explore the intricate beauty of problem-solving and code craftsmanship with unwavering
                    enthusiasm.
                  </p>
                </div>
                <div className="mt--30">
                  <h4>Full-Stack Developer Extraordinaire</h4>
                  <ul className="list-style--1">
                    {namesItemOne.map((name, index) => {
                      return (
                        <li key={index}>
                          <FiCheck /> {name}
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="mt--30">
                  <h4>Tech Maestro: JavaScript & Cloud Virtuoso</h4>
                  <ul className="list-style--1">
                    {namesItemTwo.map((name, index) => {
                      return (
                        <li key={index}>
                          <FiCheck /> {name}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              <div className="thumbnail position-relative">
                <img className="w-100" src="/assets/images/about/about-3.webp" alt="About Images" loading="lazy" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End About Area  */}

      {/* Start About Area  */}
      <div className="rn-about-area ptb--90 bg_color--5">
        <div className="container">
          <div className="row row--35">
            <div className="col-lg-6">
              <div>
                <img className="w-100" src="/assets/images/dev-city.png" alt="Developer City" loading="lazy" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-inner inner">
                <div className="section-title">
                  <h3 className="title">My Open Source Product</h3>
                  <p className="description">
                    Developers City is an open-source suite of developer tools addressing challenges with third-party tools and data leakage risks. Key features include an Encode to Decode converter
                    with a visually appealing XML view, a secure Text to Encode converter, support for JSON and CSV formats, an automated Cards Generator, a Password Generator without third-party
                    reliance, and Unique Identifier Generators. Developers City aims to provide essential functionalities in a user-friendly environment, offering theme customization, light/dark
                    modes, and PWA installation. Revolutionize your developer experience with Developers City!
                  </p>
                </div>
                <div className="accordion-wrapper mt--30">
                  <Accordion02 />
                </div>
                <div className="about-button mt--50">
                  <a className="rn-button-style--2 btn-solid" href="https://developers.city" target="_blank" rel="noopener noreferrer">
                    Visit Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End About Area  */}

      {/* Start Brand Area */}
      <div className="rn-brand-area pt--90 pb--90">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title service-style--3 text-center mb--15">
                <h2 className="title">Comprehensive Skill Set</h2>
                <p>Versatile and comprehensive professional skill set encompassing expertise in diverse domains.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <BrandTwo />
            </div>
          </div>
        </div>
      </div>
      {/* End Brand Area */}

      {/* Start Blog Area */}
      <div className="rn-blog-area pt--90 pb--90 bg_color--1">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-12">
              <div className="section-title service-style--3 text-left mb--15">
                <h2 className="title">Latest Blog</h2>
                <p>
                  Dive into our latest blog, which features insightful technical articles on the forefront of web development trends. It offers valuable insights and expert perspectives, catering to
                  both tech enthusiasts and professionals.
                </p>
              </div>
            </div>
          </div>
          <div className="row mt--55 mt_sm--30 rn-slick-dot slick-space-gutter--15 slickdot--20 row--0">
            <div className="col-lg-12">
              <Slider {...slickDot}>
                {PostList.map((value, i) => (
                  <div className="blog blog-style--1" key={i}>
                    <div className="thumbnail">
                      <a href="/blog-details">
                        <img src={`/assets/images/blog/blog-${value.images}.webp`} alt="Blog Images" loading="eager" />
                      </a>
                    </div>
                    <div className="content">
                      <p className="blogtype">{value.category}</p>
                      <h4 className="title">
                        <a href="/blog-details">{value.title}</a>
                      </h4>
                      <div className="blog-btn">
                        <a className="rn-btn text-white" href="/blog-details">
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
      {/* End Blog Area */}

      {/* Start Footer Style  */}
      <Footer />
      {/* End Footer Style  */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
      <div className="mouse-cursor cursor-outer"></div>
      <div className="mouse-cursor cursor-inner"></div>
    </div>
  );
};

export default Home;
