import React, { Component } from "react";

class BrandTwo extends Component {
  render() {
    return (
      <ul className="brand-style-2">
        <li>
          <img src="/assets/images/brand/brand-01.webp" alt="HTML 5" loading="lazy" />
        </li>
        <li>
          <img src="/assets/images/brand/brand-02.webp" alt="CSS3" loading="lazy" />
        </li>
        <li>
          <img src="/assets/images/brand/brand-03.webp" alt="Javascript" loading="lazy" />
        </li>
        <li>
          <img src="/assets/images/brand/brand-04.webp" alt="React Js" loading="lazy" />
        </li>
        <li>
          <img src="/assets/images/brand/brand-05.webp" alt="Vue Js" loading="lazy" />
        </li>
        <li>
          <img src="/assets/images/brand/brand-06.webp" alt="SQL" loading="lazy" />
        </li>
        <li>
          <img src="/assets/images/brand/brand-07.webp" alt="My SQL" loading="lazy" />
        </li>
        <li>
          <img src="/assets/images/brand/brand-08.webp" alt="Java" loading="lazy" />
        </li>
        <li>
          <img src="/assets/images/brand/brand-09.webp" alt="Node Js" loading="lazy" />
        </li>
        <li>
          <img src="/assets/images/brand/brand-10.webp" alt="Bootstrap" loading="lazy" />
        </li>
        <li>
          <img src="/assets/images/brand/brand-11.webp" alt="Node Js" loading="lazy" />
        </li>
        <li>
          <img src="/assets/images/brand/brand-12.webp" alt="Bootstrap" loading="lazy" />
        </li>
      </ul>
    );
  }
}
export default BrandTwo;
