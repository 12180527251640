import { useEffect } from "react";
import $ from "jquery";

const useCursor = () => {
  useEffect(() => {
    const tritoTmCursor = () => {
      const myCursor = $(".mouse-cursor");

      if (myCursor.length) {
        const e = document.querySelector(".cursor-inner");
        const t = document.querySelector(".cursor-outer");
        let n,
          i = 0,
          o = false;

        window.onmousemove = function (s) {
          if (!o) {
            t.style.transform = `translate(${s.clientX}px, ${s.clientY}px)`;
          }
          e.style.transform = `translate(${s.clientX}px, ${s.clientY}px)`;
          // eslint-disable-next-line
          n = s.clientY;
          i = s.clientX;
        };

        $("body").on("mouseenter", "a, .trito_tm_testimonials .avatars ul li, .cursor-pointer", function () {
          e.classList.add("cursor-hover");
          t.classList.add("cursor-hover");
        });

        $("body").on("mouseleave", "a, .trito_tm_testimonials .avatars ul li, .cursor-pointer", function () {
          if ($(this).is("a") && $(this).closest(".cursor-pointer").length === 0) {
            e.classList.remove("cursor-hover");
            t.classList.remove("cursor-hover");
          }
        });

        e.style.visibility = "visible";
        t.style.visibility = "visible";
      }
    };

    tritoTmCursor();

    // Cleanup function if needed
    return () => {
      // Perform any necessary cleanup here
    };
  }, []);
};

export default useCursor;
