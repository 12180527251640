import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { FiX, FiMenu } from "react-icons/fi";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: false, // To track whether the header should be sticky or not
    };
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll); // Listen to scroll event
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll); // Remove scroll event listener
  }

  handleScroll = () => {
    const scrollPosition = window.scrollY;
    if (scrollPosition > 200) {
      this.setState({ scrolled: true });
    } else {
      this.setState({ scrolled: false });
    }
  };

  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }

  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }

  render() {
    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement.querySelector(".submenu").classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }
    const { logo, color = "default-color" } = this.props;
    const { scrolled } = this.state;
    let logoUrl;
    if (logo === "light") {
      logoUrl = <img src="/assets/images/logo/logo-light.png" alt="Mikdan Tech Solutions" loading="eager" />;
    } else if (logo === "dark") {
      logoUrl = <img src="/assets/images/logo/logo-dark.png" alt="Mikdan Tech Solutions" loading="eager" />;
    } else if (logo === "symbol-dark") {
      logoUrl = <img src="/assets/images/logo/logo-symbol-dark.webp" alt="Mikdan Tech Solutions" loading="eager" />;
    } else if (logo === "symbol-light") {
      logoUrl = <img src="/assets/images/logo/logo-symbol-light.png" alt="Mikdan Tech Solutions" loading="eager" />;
    } else {
      logoUrl = <img src="/assets/images/logo/logo.webp" alt="Mikdan Tech Solutions" loading="eager" />;
    }

    return (
      <header className={`header-area formobile-menu ${scrolled ? "sticky-header01 header--fixed" : "header--transparent"} ${color}`}>
        <div className="header-wrapper" id="header-wrapper">
          <div className="header-left">
            <div className="logo">
              <NavLink to="/" activeClassName="active-link">
                {logoUrl}
              </NavLink>
            </div>
          </div>
          <div className="header-right">
            <nav className="mainmenunav d-lg-block">
              <ul className="mainmenu">
                <li>
                  <NavLink exact to="/" activeClassName="active">
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/about" activeClassName="active">
                    About Me
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/experience" activeClassName="active">
                    Experience
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/blog" activeClassName="active">
                    Blog
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/contact" activeClassName="active">
                    Contact
                  </NavLink>
                </li>
              </ul>
            </nav>
            {/* Start Humberger Menu  */}
            <div className="humberger-menu d-block d-lg-none pl--20">
              <span onClick={this.menuTrigger} className="menutrigger text-white">
                <FiMenu />
              </span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu d-block d-lg-none">
              <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                <FiX />
              </span>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default Header;
