import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { FiX, FiMenu } from "react-icons/fi";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: false,
    };
    this.handleScroll = this.handleScroll.bind(this);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    const scrollPosition = window.scrollY;
    if (scrollPosition > 200) {
      this.setState({ scrolled: true });
    } else {
      this.setState({ scrolled: false });
    }
  }
  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }
  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }
  render() {
    const { location } = this.props;
    const { pathname } = location;
    const { scrolled } = this.state;
    const headerClass = scrolled ? "header-area formobile-menu sticky-header" : "header-area formobile-menu";
    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement.querySelector(".submenu").classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }

    const { logo, color, headerPosition } = this.props;
    let logoUrl;
    if (logo === "light") {
      logoUrl = <img src="/assets/images/logo/logo-light.png" alt="Mikdan Tech Solutions" loading="eager" />;
    } else if (logo === "dark") {
      logoUrl = <img src="/assets/images/logo/logo-dark.png" alt="Mikdan Tech Solutions" loading="eager" />;
    } else if (logo === "symbol-dark") {
      logoUrl = <img src="/assets/images/logo/logo-symbol-dark.webp" alt="Mikdan Tech Solutions" loading="eager" width="60" height="60" />;
    } else if (logo === "all-dark") {
      logoUrl = <img src="/assets/images/logo/logo-all-dark.png" alt="Mikdan Tech Solutions" loading="eager" />;
    } else if (logo === "symbol-light") {
      logoUrl = <img src="/assets/images/logo/logo-symbol-light.png" alt="Mikdan Tech Solutions" loading="eager" />;
    } else {
      logoUrl = <img src="/assets/images/logo/logo.webp" alt="Mikdan Tech Solutions" loading="eager" width="66" height="66" />;
    }

    return (
      <header className={`${headerClass} ${headerPosition} ${color}`}>
        <div className="header-wrapper" id="header-wrapper">
          <div className="header-left">
            <div className="logo">
              <Link to="/">{logoUrl}</Link>
            </div>
          </div>
          <div className="header-right">
            <nav className="mainmenunav d-lg-block">
              <ul className="mainmenu">
                <li className={pathname === "/" ? "active" : ""}>
                  <Link to="/">Home</Link>
                </li>
                <li className={pathname === "/about" ? "active" : ""}>
                  <Link to="/about">About Me</Link>
                </li>
                <li className={pathname === "/experience" ? "active" : ""}>
                  <Link to="/experience">Experience</Link>
                </li>
                <li className={pathname === "/blog" ? "active" : ""}>
                  <Link to="/blog">Blog</Link>
                </li>
                <li className={pathname === "/contact" ? "active" : ""}>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </nav>
            {/* Start Humberger Menu  */}
            <div className="humberger-menu d-block d-lg-none pl--20">
              <span onClick={this.menuTrigger} className="menutrigger text-white">
                <FiMenu />
              </span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu d-block d-lg-none">
              <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                <FiX />
              </span>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default withRouter(Header);
