import React from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import CounterOne from "../elements/counters/CounterOne";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderTwo";
import Footer from "../component/footer/Footer";
import useCursor from "../useCursor.js";

const About = () => {
  useCursor();
  return (
    <>
      <div className="trito_tm_all_wrap" data-magic-cursor="show"></div>

      <PageHelmet pageTitle="About" pageDescription="Mikdan Tech Solutions" pageKeywords={["Profile"]} />

      <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

      {/* Start Breadcrump Area */}
      <Breadcrumb title={"About Me"} />
      {/* End Breadcrump Area */}

      {/* Start About Area  */}
      <div className="rn-about-area ptb--90 bg_color--1">
        <div className="rn-about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-5">
                <div className="thumbnail">
                  <img className="w-100" src="/assets/images/about/about-3.webp" alt="About Images" />
                </div>
              </div>
              <div className="col-lg-7">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">What I do</h2>
                    <p className="description">
                      I am Manikandan A, a seasoned Principal Software Engineer with over 8 years of experience in the IT industry, specializing in Full Stack development. My expertise lies in
                      crafting dynamic and scalable web applications using React, Node.js, and MongoDB. Throughout my career, I have demonstrated a strong ability to lead development projects from the
                      ground up, ensuring that all aspects of software development, from conceptual design to deployment, are executed with precision and efficiency.
                    </p>
                  </div>
                  <div className="row mt--30">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">Expertise</h3>
                        <p style={{ textAlign: "justify" }}>
                          My technical skill set extends to integrating DevOps practices into the software development lifecycle, enhancing collaboration, and streamlining deployment processes.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">Proactive</h3>
                        <p style={{ textAlign: "justify" }}>
                          My goal is to leverage my full-stack development and DevOps expertise to drive technological advancements and achieve business objectives.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End About Area  */}

      {/* Start CounterUp Area */}
      <div className="rn-counterup-area ptb--90 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center">
                <h3 className="fontWeight500">MILESTONES</h3>
              </div>
            </div>
          </div>
          <CounterOne />
        </div>
      </div>
      {/* End CounterUp Area */}

      {/* Start Finding Us Area  */}
      <div className="rn-finding-us-area rn-finding-us ptb--90 bg_color--1">
        <div className="inner">
          <div className="content-wrapper">
            <div className="content">
              <h4 className="theme-gradient">WE HELP CORPORATE BUSINESS GROW TOGETHER.</h4>
              <p>Our team of experts works with the latest tools and technologies to deliver highly performant & scalable software products.</p>
              <a className="rn-btn btn-white" href="/contact">
                Get Started
              </a>
            </div>
          </div>
          <div className="thumbnail">
            <div className="image">
              <img src="/assets/images/about/finding-us-01.png" alt="Finding Images" />
            </div>
          </div>
        </div>
      </div>
      {/* End Finding Us Area  */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      <Footer />
      <div className="mouse-cursor cursor-outer"></div>
      <div className="mouse-cursor cursor-inner"></div>
    </>
  );
};

export default About;
