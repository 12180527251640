import React, { Component } from "react";
import { FaFacebookF, FaLinkedinIn, FaWhatsapp, FaGithub, FaSkype } from "react-icons/fa";
import MySwitcher from "../../elements/MySwitcher";
import { Link, withRouter } from "react-router-dom";

const SocialShare = [
  {
    Social: <FaWhatsapp />,
    link: "https://api.whatsapp.com/send?phone=917200339195&text=Hello%20Manikandan",
    name: "Whatsapp",
  },
  {
    Social: <FaLinkedinIn />,
    link: "https://www.linkedin.com/in/mikdanjey",
    name: "LinkedIn",
  },
  {
    Social: <FaGithub />,
    link: "https://github.com/mikdanjey",
    name: "Github",
  },
  {
    Social: <FaSkype />,
    link: "skype:mikdanjey?add",
    name: "Skype",
  },
  {
    Social: <FaFacebookF />,
    link: "https://www.facebook.com/Mikdanjey",
    name: "Facebook",
  },
];

const currentYear = new Date().getFullYear();

class Footer extends Component {
  render() {
    const { location } = this.props;
    const { pathname } = location;
    return (
      <>
        <footer className="footer-area">
          <div className="footer-wrapper">
            <div className="row align-items-end row--0">
              <div className="col-lg-6">
                <div className="footer-left">
                  <div className="inner">
                    <span>Ready To Do This</span>
                    <h2>
                      Let's get <br /> to work
                    </h2>
                    <a className="rn-button-style--2" href="/contact">
                      <span>Hire Now</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="footer-right" data-black-overlay="6">
                  <div className="row">
                    {/* Start Single Widget  */}
                    <div className="col-lg-6 col-sm-6 col-12">
                      <div className="footer-link">
                        <h4>Quick Links</h4>
                        <ul className="ft-link">
                          <li className={pathname === "/about" ? "active" : ""}>
                            <Link to="/about">About Me</Link>
                          </li>
                          <li className={pathname === "/experience" ? "active" : ""}>
                            <Link to="/experience">Experience</Link>
                          </li>
                          <li className={pathname === "/blog" ? "active" : ""}>
                            <Link to="/blog">Blog</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* End Single Widget  */}
                    {/* Start Single Widget  */}
                    <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                      <div className="footer-link">
                        <h4>
                          Say Hello
                          <span className="wave-emoji">
                            <img alt="👋" draggable="false" src="/assets/images/other/emoji1.png" className="emoji-image" />
                          </span>
                        </h4>
                        <ul className="ft-link">
                          <li>
                            <a href="mailto:mikdanjey@gmail.com">mikdanjey@gmail.com</a>
                          </li>
                          <li>
                            <a href="/">www.mikdantech.com</a>
                          </li>
                        </ul>

                        <div className="social-share-inner">
                          <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                            {SocialShare.map((item, i) => (
                              <li key={i + ""}>
                                <a href={`${item.link}`} target="_blank" rel="noopener noreferrer" aria-label={item.name}>
                                  {item.Social}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* End Single Widget  */}
                    <div className="col-lg-12">
                      <div className="copyright-text">
                        <p>
                          &copy;&nbsp;{currentYear} My Profile, All Rights Reserved | Built by{" "}
                          <a href="/" className="foot-link">
                            Manikandan A
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <MySwitcher />
      </>
    );
  }
}
export default withRouter(Footer);
