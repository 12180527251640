import { useEffect } from "react";

const PageScrollTop = ({ children }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return children;
};

export default PageScrollTop;
