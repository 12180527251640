import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";

const MySwitcher = () => {
  const [activeStyle, setActiveStyle] = useState(Cookies.get("styleCookieName") || "light");

  useEffect(() => {
    updateBodyClass();
  }, [activeStyle]);

  const updateBodyClass = () => {
    if (activeStyle === "dark") {
      document.body.classList.remove("active-light-mode");
      document.body.classList.add("active-dark-mode");
    } else {
      document.body.classList.remove("active-dark-mode");
      document.body.classList.add("active-light-mode");
    }
  };

  const setStyleCookie = (style) => {
    Cookies.set("styleCookieName", style, { expires: 7 });
    setActiveStyle(style);
  };

  const handleSwitchClick = (style) => {
    setStyleCookie(style);
  };

  return (
    <div id="my_switcher" className="my_switcher">
      <ul>
        <li>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleSwitchClick("light");
            }}
            className={`setColor light ${activeStyle === "light" ? "active" : ""}`}
          >
            <img src="/assets/images/about/sun-01.svg" alt="Sun images" />
            <span title="Light Mode"> Light</span>
          </a>
        </li>
        <li>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleSwitchClick("dark");
            }}
            className={`setColor dark ${activeStyle === "dark" ? "active" : ""}`}
          >
            <img src="/assets/images/about/vector.svg" alt="Vector Images" />
            <span title="Dark Mode"> Dark</span>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default MySwitcher;
